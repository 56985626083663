<template>
  <div class="fill-height">
    <div
      class="overflow-y-auto pa-0 ma-0 mx-auto fill-height"
      :style="{paddingBottom: noBottom ? '0 !important' : '4.375rem !important'}"
    >
      <slot />
    </div>
  </div>
</template>

<script>
    export default {
        name: "AdditionalInformationWrapper",
        props: {
            noBottom: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isAdditionalInformationWrapper: true,
            };
        },
    }
</script>

<style scoped>
</style>
