<template>
  <v-speed-dial
    v-model="fab"
    bottom
    right
    direction="top"
    transition="slide-y-reverse-transition"
    style="z-index: 11;"
  >
    <template v-slot:activator>
      <v-btn
        v-if="user.isAdmin || (user.isWorker && !(item.isWorkorderStateInProgress && !itsMe) &&
          !(item.isWorkorderStateWatingStaff && !itsMe))"
        v-show="showable"
        v-model="fab"
        color="#2196f3"
        dark
        fab
        class="speed-dialog-button"
      >
        <v-icon v-if="fab">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-hammer-wrench
        </v-icon>
      </v-btn>
    </template>
    <!--    &lt;!&ndash; Block Overlay &ndash;&gt;-->
    <!--    <div-->
    <!--      style="opacity: 0; position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 7;"-->
    <!--      @click.self.stop.prevent="onClickOverlay"-->
    <!--    />-->
    <!-- 관리자 전용 -->
    <as-speed-dialog-for-admin
      v-if="user.isAdmin"
      :item="item"
      :options="asOptions"
      :button-click-callback="onButtonClicked"
    />
    <!-- 작업자 전용 -->
    <as-speed-dialog-for-worker
      v-else-if="user.isWorker"
      :item="item"
      :options="asOptions"
      :button-click-callback="onButtonClicked"
    />
  </v-speed-dial>
</template>

<script>
    import AsSpeedDialogForAdmin from "@/components/part/as/speed-dialog/AsSpeedDialogForAdmin";
    import Work from "@/model/work";
    import CodeOptions from "@/components/mixins/CodeOptions";
    import AsSpeedDialogForWorker from "@/components/part/as/speed-dialog/AsSpeedDialogForWorker";

    export default {
        name: "AsSpeedDialog",
        components: {AsSpeedDialogForWorker, AsSpeedDialogForAdmin},
        mixins: [CodeOptions],
        props: {
            item: {
                type: Work,
                default: undefined
            },
            showing: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                fab: false,
                showable: false,
            };
        },
        computed: {
            itsMe() {
                return this.item.raw.worker === this.user.userId;
            },
        },
        watch: {
            showing(newValue) {
                if (newValue) {
                    this.showable = false;
                    setTimeout(() => {
                        this.showable = true;
                    }, 500);
                }
            },

            fab() {
                console.log("speed-dialog watch fab: " + this.fab);
                if (this.fab) {
                    this.$showBlockOverlay(() => {
                        this.fab = false;
                        this.backGuard.pop();
                    });
                    this.backGuard.push(() => {
                        this.fab = false;
                        this.$hideBlockOverlay();
                    });
                }
            }
        },
        created() {
            this.loadOptionsForWorkorderStaff();
            this.loadOptionsForDoneReasonCode();
            setTimeout(() => {
                this.showable = true;
            }, 500);
        },
        mounted() {
            setTimeout(() => {
                this.showable = true;
            }, 500);
        },
        unmounted() {
            this.showable = false;
        },
        methods: {
            // onClickOverlay() {
            //     this.backGuard.pop();
            //     this.fab = false;
            //     this.$hideBlockOverlay();
            // },

            onButtonClicked() {
                this.backGuard.pop();
                this.$hideBlockOverlay();
            },
        },
    }
</script>

<style scoped>
    /*#5200FF
    #00B9AE
    #EC4DA3
    #FF6B00
    #0C98FE*/

    /**
      버튼 컬러
    */
    /* 배정 */
    >>> .assign-fab-button {
      background-color: #2A5270 !important;
    }

    /* 수락 */
    >>> .accept-fab-button {
      background-color: #2A5270 !important;
    }

    /* 위임 */
    >>> .delegate-fab-button {
      background-color: #2A5270 !important;
    }

    /* 예약 */
    >>> .reserve-fab-button {
      background-color: #2A5270 !important;
    }

    /* 취소 */
    >>> .cancel-fab-button {
      background-color: #2A5270 !important;
    }

    /* 완료 */
    >>> .complete-fab-button {
      background-color: #2A5270 !important;
    }

    /* 재등록 */
    >>> .clone-fab-button {
      background-color: #2A5270 !important;
    }

    /* 재서명 */
    >>> .complete-resign-button {
      background-color: #2A5270 !important;
    }

    .speed-dialog-button {
        -webkit-backface-visibility: hidden;
    }

    .speed-dialog-button {
        animation-name: dial;
        animation-duration: 0.2s;
        /*animation-delay: 0.5s;*/
    }

    @keyframes dial {
        0% {
            transform: scale(0)
        }
        100% {
            transform: scale(1)
        }
    }
</style>
