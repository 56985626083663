import { render, staticRenderFns } from "./AsDetailAdditionalInformationHeader.vue?vue&type=template&id=62478473&scoped=true"
import script from "./AsDetailAdditionalInformationHeader.vue?vue&type=script&lang=js"
export * from "./AsDetailAdditionalInformationHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62478473",
  null
  
)

export default component.exports