<template>
  <div
    class="fill-height"
  >
    <v-tabs
      v-model="model"
      centered
      class="fill-height overflow-y-hidden"
      fixed-tabs
    >
      <v-tab
        v-for="(tab, index) in tabs"
        :key="index"
      >
        {{ tab.title }}
      </v-tab>


      <v-tabs-items
        v-model="model"
        class="fill-height overflow-y-hidden"
        style="transition: none;"
      >
        <v-tab-item
          :key="0"
          class="fill-height overflow-y-hidden"
        >
          <balance-tabs-items
            v-if="model === 0"
            :account-id="accountId"
            :change-id="changeId"
          />
        </v-tab-item>
        <v-tab-item
          :key="1"
          class="fill-height overflow-y-hidden"
        >
          <as-history-tabs-items
            v-if="model === 1"
            :workorder-id="workorderId"
            :change-id="changeId"
          />
        </v-tab-item>
        <v-tab-item
          :key="2"
          class="fill-height overflow-y-hidden"
        >
          <as-latest-tabs-items
            v-if="model === 2"
            :workorder-id="workorderId"
            :account-id="accountId"
            :change-id="changeId"
          />
        </v-tab-item>
        <v-tab-item
          :key="3"
          class="fill-height overflow-y-hidden"
        >
          <contract-tabs-items
            v-if="model === 3"
            :account-id="accountId"
            :change-id="changeId"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<style scoped>
    .v-tab--active {
        color: #000 !important;;
    }

    .v-tab {
        color: #000 !important;
    }

    >>> .v-slide-group__prev.v-slide-group__prev--disabled {
        display: none !important;
    }
</style>

<script>
    import BalanceTabsItems from "@/components/widgets/additional/slot/tabs-items/BalanceTabsItems";
    import AsHistoryTabsItems from "@/components/widgets/additional/slot/tabs-items/AsHistoryTabsItems";
    import AsLatestTabsItems from "@/components/widgets/additional/slot/tabs-items/AsLatestTabsItems";
    import ContractTabsItems from "@/components/widgets/additional/slot/tabs-items/ContractTabsItems";

    export default {
        name: "AsDetailAdditionalInformationContent",
        components: {ContractTabsItems, AsLatestTabsItems, AsHistoryTabsItems, BalanceTabsItems},
        props: {
            accountId: null,
            workorderId: null,
            expanded: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                model: null,
                tabs: [],
                changeId: 0,
            };
        },
        watch: {
            expanded(newValue) {
                if (newValue) {
                  this.changeId++;
                }
            },
        },
        created() {
            this.initTabs();
        },
        methods: {
            initTabs() {
                this.tabs = [
                    {
                        title: this.$translate("미수금액"),
                    },
                    {
                        title: this.$translate("변경내역"),
                    },
                    {
                        title: this.$translate("최근 A/S"),
                    },
                    {
                        title: this.$translate("계약정보"),
                    }
                ];
            },
        },
    }
</script>