<template>
  <div>
    <!-- 인피니트 리스트 -->
    <vue-infinite-loader
      :items="itemsOverwrite"
      :fetch="fetch"
      :identifier="identifier"
      item-key="raw.workorderId"
      :loading="loading"
      @update:inner-list="v => innerList = v"
    >
      <template #default="{ item }">
        <as-item-card
          :item="item"
          :options="asOptions"
        />
      </template>
    </vue-infinite-loader>

    <!-- 새로고침 버튼 -->
    <refresh-button
      :container="mounted ? $refs.container : undefined"
      no-bottom
      @click="onRefresh"
    />
  </div>
</template>

<script>
    import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
    import {CODE, PATH} from "@/constants";
    import Work from "@/model/work";
    import OnMainSearchTriggered from "@/event/OnMainSearchTriggered";
    import OnMainFilterTriggered from "@/event/OnMainFilterTriggered";
    import OnFilterResetListener from "@/framework/listeners/OnFilterResetListener";
    import RefreshButton from "@/components/comp/RefreshButton";
    import {NATIVE_EVENT} from "@/assets/plugins/mps-mobile-native-bridge/native-bridge";
    import CodeOptions from "@/components/mixins/CodeOptions";
    import AsItemCard from "@/components/items/as/AsItemCard";
    import OnWorkorderStatusUpdatedListener from "@/framework/listeners/OnWorkorderStatusUpdatedListener";

    export default {
        name: "AsMainDayFinishSubList",
        components: {AsItemCard, RefreshButton},
        extends: WindowComponent,
        mixins: [OnMainSearchTriggered, OnMainFilterTriggered, OnFilterResetListener, CodeOptions, OnWorkorderStatusUpdatedListener],
        data() {
            return {
                items: [],
                itemsOverwrite: [],
                searchOptions: {},
                identifier: 0,
                mounted: false,
                loading: false,
              innerList: [],
              loadKey: 0,
            }
        },
        unmounted() {
            this.$nativeEventBus.$off(NATIVE_EVENT.ON_NOTIFICATION_RECEIVED, this.loadOverwrite);
        },
      created() {
        this.initSearchOptions();
      },
        mounted() {
            this.mounted = true;
          this.$nativeEventBus.$on(NATIVE_EVENT.ON_NOTIFICATION_RECEIVED, this.loadOverwrite);
          if (this.historyManager.isForward) { // pushState 한 경우만 초기화
            this.initSearchOptions();
            this.loadInit();
          } else if (this.historyManager.isBack) {
            this.loadAllUpToCurrent(true);
          }
          this.loadOptions();
        },
        methods: {
          onWorkorderStatusUpdated() {
            this.loadAllUpToCurrent(true);
          },
            /**
             * 목록을 불러와 현재 페이징된 아이템에 덮어씁니다.
             */
            loadOverwrite() {
                this.loadAllUpToCurrent(true);
            },

            loadInit() {
                this.loadAllUpToCurrent();
                this.identifier++;
            },

            loadOptions() {
                setTimeout(() => {
                    this.loadOptionsForWorkorderStaff();
                    this.loadOptionsForDoneReasonCode()
                }, 500);
            },

            /**
             * 목록을 불러옵니다.
             */
            async loadAllUpToCurrent(overwrite = false) {
              const loadKey = ++this.loadKey;

              const endDate = this.$parseDate(this.searchOptions.endDate);
              if (!endDate || endDate.ts > this.$now().endOf("day").ts) {
                this.searchOptions.endDate = this.$now().endOf("day").toISO();
              }

              this.loading = true;
                const response = await this.$request(PATH.WORK_ORDER.LIST)
                    .setObject({
                        ...this.searchOptions,
                    })
                    .wait(500)
                    .enqueue();
              this.loading = false;
              if (loadKey !== this.loadKey) return;
                const temp = response.data.map(item => new Work(item));
                const sorted = temp.sort((a, b) => {
                    return b.doneDatetimeObj.ts- a.doneDatetimeObj.ts
                });
                if (overwrite) {
                    this.itemsOverwrite = sorted.splice(0, this.innerList.length);
                } else {
                    this.items = sorted;
                    this.identifier++;
                }
                this.loading = false;
            },

            /**
             * 불러온 목록에서 무한 페이징에 사용할 목록만 반환합니다.
             */
            fetch(pageNumber, pageSize) {
                console.log("asmainDayFinishSubList fetch")
                if (this.$isEmpty(this.items)) return [];
                return this.items.splice(0, 10);
            },

            /**
             * 검색키워드와 필터 조건을 초기화 합니다.
             */
            initSearchOptions() {
                this.searchOptions = {};
                this.initFilterState();
            },

            /**
             * 필터 조건을 초기화 합니다.
             */
            initFilterState() {
                this.searchOptions.workorderStateDayList = [CODE.AS.COMPLETED];
            },

            /**
             * 툴바 검색 버튼이 눌렸습니다.
             */
            onMainSearchTriggered(keyword) {
                this.searchOptions.searchKeyword = keyword;
                this.loadInit();
            },

            /**
             * 필터 적용이 되었습니다.
             */
            onMainFilterTriggered(state) {
                this.searchOptions = {...this.searchOptions, ...state};
                this.loadInit();
            },

            /**
             * 필터 조건을 초기화하고 리로드합니다.
             */
            onFilterReset() {
                this.initSearchOptions();
                this.loadInit();
            },

            /**
             * 새로고침 버튼을 클릭하였습니다.
             *
             * 필터조건 변경없이 목록 데이터만 새로 로드합니다.
             */
            onRefresh() {
                this.loadInit();
            },
        }
    }
</script>

