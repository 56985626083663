<!--
   A/S 접수와 동시에 완료 정보입력을 위한 컴포넌트
   AsSuccessVuetifyModal (A/S 접수완료 모달) 기능을 접수에서 사용할 수 있도록 만들어진 컴포넌트입니다.
-->
<template>
  <div>
    <!-- 토글 버튼 -->
    <v-switch
      v-model="innerIsUse"
      :label="'접수와 동시에 완료' | translate"
      inset
      style="margin: 4px"
    />
    <div
      v-show="innerIsUse"
      class="overflow-y-hidden overflow-x-hidden"
    >
      <span
        class="font__13 color__333 text--color d-flex align-center px__18"
        style="height: 32px; background: #f4f4f4;"
      >
        {{ '완료 처리 내역' | translate }}
      </span>
      <!-- 처리내용 -->
      <v-textarea
        ref="asContentTextarea"
        v-model="editItem.asContent"
        v-focus-delay:child-textarea="400"
        :label="'처리내용' | translate"
        hide-details
        outlined
        rows="10"
        class="basic-style"
      />

      <!-- 처리유형 선택 -->
      <v-select-backguard
        ref="doneReasonSelect"
        v-model="editItem.doneReasonCode"
        :items="doneReasonCodeOptions"
        item-text="codeValue"
        item-value="codeKey"
        :label="'처리유형' | translate"
        hide-details
        outlined
        class="basic-style"
      />

      <div
        style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;"
        class="basic-style"
      >
        <span>{{ '서명' | translate }}</span>
        <!-- 서명 초기화 -->
        <v-btn
          icon
          @click="clearPad"
        >
          <v-icon>mdi-autorenew</v-icon>
        </v-btn>
      </div>

      <!-- 서명 패드 -->
      <div
        ref="signaturePadContainer"
        style="width: 100%; height: 100%; min-height: 400px; max-height: 400px; background-color: rgba(245, 245,245, 0.7);"
      >
        <VueSignaturePad
          ref="signaturePad"
          :option="padOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CodeOptions from "../../mixins/CodeOptions.vue";
import {now} from "lodash";
import VSelectBackguard from "../../part/VSelectBackguard.vue";

export default {
  name: "AsAutoSuccessComponent",
  components: {VSelectBackguard},
  mixins: [CodeOptions],
  props: {
    // A/S 접수와 동시에 완료 사용 여부
    isUse: {
      type: Boolean,
      default: false,
    },

    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // 처리사유코드 선택 목록
      doneReasonCodeOptions: [],
      innerIsUse: false,
      editItem: {
        // 처리완료 일시
        doneDate: now(),
        // 처리 내역
        asContent: null,
        // 처리사유코드
        doneReasonCode: null,
      },
      // 서명 패드 옵션
      padOptions: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)"
      },
    }
  },
  watch: {
    isUse(newValue) {
      this.innerIsUse = newValue
    },

    innerIsUse(newValue) {
      if(newValue){
        this.clearData()
        this.resizePad()
      }
      this.$emit('update:is-use', newValue)
    },

    item: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.editItem = newValue
      },
    },

    editItem: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('update:item', newValue)
      },
    },
  },
  created() {
    this.loadOptions();
  },
  methods: {
    loadOptions() {
      this.loadOptionsForWorkorderStaff();
      this.loadOptionsForDoneReasonCode()
    },

    /**
     * 서명패드 초기화
     */
    clearPad() {
      this.$refs.signaturePad.clearSignature();
    },

    /**
     * 서명 정보 반환
     * @returns {null|*}
     */
    getSignature() {
      const {isEmpty, data} = this.$refs.signaturePad.saveSignature();
      return isEmpty ? null : data;
    },

    /**
     * AsSuccessVuetifyModal 에 있는 기능을 가져와 사용합니다.
     * canvas 의 width, height 속성이 컨테이너(div 태그) 의 width, height style 과 다르면
     * 모바일에서 실제로 그려지는 위치가 달라져 버그가 발생한 것으로 보입니다.
     * 그래서 강제적으로 mounted 시 canvas 의 width, height 속성을 조절해 줍니다(canvas 의 style width, height 는 제대로 작동하지 않습니다)
     */
    resizePad() {
      this.$nextTick(() => {
        const container = this.$refs.signaturePadContainer;
        if (!container) {
          console.error("signaturePadContainer is undefined");
          return;
        }
        const canvas = this.$refs.signaturePad.$el.querySelector('canvas');
        if (!canvas) {
          console.error("canvas is undefined");
          return;
        }
        canvas.setAttribute('width', container.clientWidth);
        canvas.setAttribute('height', container.clientHeight);
      })
    },

    /**
     * 내부 데이터 초기화
     */
    clearData(){
      this.editItem = {
          doneDate: now(),
          asContent: null,
          doneReasonCode: null,
      }
      this.clearPad()
    }
  },
}
</script>

<style scoped>
.basic-style {
  margin: 10px 5px;
}

.text--color {
  color: #2196f3;
}
</style>
