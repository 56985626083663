<template>
  <div
    class="text-left as-content px__18"
    :class="{ expanded: expand }"
    style="height: 100%"
  >
    <v-row class="mx-0 mt-0 font__14">
      <v-col
        cols="12"
        class="pa-0"
      >
        <!-- A/S변경내역 건수를 보여줍니다 -->
        <template v-if="!loadingAsChangedHistory">
          {{ 'A/S변경내역' | translate }} : <span>{{ countChangedHistory }}{{ '건' | translate }}</span>
          <span class="color__aaa px__10 pl-4">|</span>
        </template>

        <!-- 계약정보 건수를 보여줍니다 -->
        <template v-if="!loadingContract">
          {{ '계약정보' | translate }} : <span>{{ countContract }}{{ '건' | translate }}</span>
        </template>
      </v-col>
    </v-row>

    <!-- 미수금 로딩이 완료될 때까지 보여주지 않습니다. 단 공간을 유지하기 위해서 v-show 를 사용합니다 -->
    <div 
      v-if="salesConfigPermission"
      v-show="!loadingBalance"
      style="height: 15px;"
      class="font__14"
    >
      <!-- 미수금이 존재합니다. -->
      <template v-if="balance.sumBalance > 0">
        <span class="font__14">{{ '미수금' | translate }} : {{ balance.outstandingMonths }} {{ '개월' | translate }} {{ balance.sumBalance | currency }}({{ '원' | translate }})</span>
      </template>
      <!-- 미수금이 없습니다. -->
      <template v-else>
        <span>{{ '미수금이 없습니다.' | translate }}</span>
      </template>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>
    import {PATH} from "@/constants";
    import OnEtcRequestSuccessed from "@/event/OnEtcRequestSuccessed";
    import userManager from "@/assets/plugins/mps-common/user/user-manager";
    import Permissions from "@/constants/permissions";

    export default {
        name: "AsDetailAdditionalInformationHeader",
        mixins: [OnEtcRequestSuccessed],
        props: {
            accountId: null,
            workorderId: null,
            expand: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                /**
                 * 미수금 로딩중.
                 */
                loadingBalance: false,
                /**
                 * A/S 변경내역 로딩중.
                 */
                loadingAsChangedHistory: false,
                /**
                 * 계약정보 로딩중
                 */
                loadingContract: false,
                /**
                 * 거래처 아이디에 의해 로드되는 정보가 한 라이프 사이클동안 이미 로드 되었는지 여부를 검증합니다.
                 */
                alreadyLoadedByAccountId: false,
                /**
                 * 작업 아이디에 의해 로드되는 정보가 한 라이프 사이클동안 이미 로드 되었는지 여부를 검증합니다.
                 */
                alreadyLoadedByWorkorderId: false,
                /**
                 * 로드된 미수금 정보입니다.
                 */
                balance: {},
                /**
                 * 로드된 변경내역 건수입니다.
                 */
                countChangedHistory: 0,
                /**
                 * 로드된 계약정보 건수입니다.
                 */
                countContract: 0,
            }
        },
      computed: {
        salesConfigPermission() {
          return userManager.hasPermission(Permissions.EXPOSE_ACCOUNT_INFO_SALES_CONFIG)
        }
      },
        watch: {
            accountId() {
                this.processLoadOnceByAccountId(true);
            },

            workorderId() {
                this.processLoadOnceByAccountId(true);
            },
        },
        /**
         * 초기 로드입니다.
         */
        created() {
            this.processLoadOnceByAccountId(true);
            this.processLoadOnceByWorkorderId(true);
        },
        /**
         * 페이지를 불러올 때마다 매 번 로드합니다. 단, watch 에 의해 이미 로드된 경우 로드하지 않습니다.
         */
        mounted() {
            if (!this.alreadyLoadedByAccountId) {
                this.processLoadOnceByAccountId(false);
            }

            if (!this.alreadyLoadedByWorkorderId) {
                this.processLoadOnceByWorkorderId(false);
            }

            /**
             * 여기에서 사용하는 라이프 사이클 중 actiavted 가 가장 마지막입니다.
             * 그러므로 라이프 사이클이 다시 시작될 때 로드될 수 있도록 플래그를 false 로 바꾸어 줍니다.
             */
            this.alreadyLoadedByAccountId = false;
            this.alreadyLoadedByWorkorderId = false;
        },
        methods: {
            processLoadOnceByAccountId(loading = true) {
                if (!this.accountId) return;
                this.loadBalanceByAccount(loading);
                this.loadContractCount(loading);
                this.alreadyLoadedByAccountId = true;
            },

            processLoadOnceByWorkorderId(loading = true) {
                if (!this.workorderId) return;
                this.loadAsChangedHistoryCount(loading);
                this.alreadyLoadedByWorkorderId = true;
            },

            /**
             * 미수금을 로드합니다.
             */
            async loadBalanceByAccount(loading = true) {
                if (this.loadingBalance) return;
                this.loadingBalance = loading;
                try {
                    this.balance = await this.$request(PATH.WORK_ORDER.AS_OUTSTANDING_AMOUNT)
                        .setObject({accountId: this.accountId})
                        .enqueue();
                } catch (error) {
                    console.error(error);
                    // 실패 메시지를 사용자에게 보여주지 않습니다.
                }
                this.loadingBalance = false;
            },

            /**
             * 계약정보 건수를 로드합니다.
             */
            async loadContractCount(loading = true) {
                if (this.loadingContract) return;
                this.loadingContract = loading;
                try {
                    this.countContract = await this.$request(PATH.CONTRACT.COUNT)
                        .setObject({accountId: this.accountId})
                        .enqueue();
                } catch (error) {
                    console.error(error);
                    // 실패 메시지를 사용자에게 보여주지 않습니다.
                }
                this.loadingContract = false;
            },

            /**
             * A/S 변경내역 건수를 로드합니다.
             */
            async loadAsChangedHistoryCount(loading = true) {
                if (this.loadingAsChangedHistory) return;
                this.loadingAsChangedHistory = loading;
                try {
                    this.countChangedHistory = await this.$request(PATH.WORK_ORDER.HISTORY_COUNT)
                        .setObject({workorderId: this.workorderId})
                        .enqueue();
                } catch (error) {
                    console.error(error);
                    // 실패 메시지를 사용자에게 보여주지 않습니다.
                }
                this.loadingAsChangedHistory = false;
            },

            /**
             * 미수금 정보가 변경되었습니다.
             */
            onEtcRequestSuccessed() {
                this.loadBalanceByAccount(false);
            }
        },
    }
</script>
