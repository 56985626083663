<template>
  <as-register-comp
    :staff-id="staffId"
    :account="account"
  />
</template>

<script>
    import AsRegisterComp from "@/components/windows/as/AsRegisterComp";

    export default {
        name: "AsRegister",
        components: {AsRegisterComp},
        props: {
          staffId: undefined,
          account:undefined,
        },
    }
</script>

<style scoped>
</style>
