<template>
  <div
    class="d-flex justify-space-between align-center mt-0 mx-0 px__18"
    style="height: 60px; border-top: 1px solid #ddd; border-bottom: 1px solid #ddd;"
  >
    <span
      class="font__13 color__333 text--color"
      style="width: 100%; display: flex;justify-content: space-between; align-items: center;"
    >
      <span style="font-size: 1rem;">{{ "신규 거래처 A/S 등록" | translate }}</span>
      <v-switch
        v-if="editPermission"
        v-model="model"
        inset
      />
      <span v-else>
        <no-items
          title="권한 없음"
        />
      </span>
    </span>
  </div>
</template>

<script>
    import Permissions from "@/constants/permissions";
    import userManager from "@/assets/plugins/mps-common/user/user-manager";
    import NoItems from "@/components/items/NoItems.vue";

    export default {
        name: "AsRegisterNewAccountButton",
      components: {NoItems},
        props: {
            propsData: {
                type: Object, default: () => {
                }
            },
            value: undefined,
        },
        data() {
            return {
                model: this.value,
            };
        },
      computed: {
        editPermission() {
          return userManager.hasPermission(Permissions.EXPOSE_ACCOUNT_INFO_REGIST_EDIT)
        }
      },
        watch: {
            value() {
                this.model = this.value;
            },

            model() {
              console.log('#### this.model', this.model)
                this.$emit("update:input", this.model);
            }
        },
    }
</script>

<style scoped>
    .text--color {
        color: #2196f3;
    }
</style>