<template>
  <as-detail-comp :workorder-id="workorderId" />
</template>

<script>
    import AsDetailComp from "@/components/windows/as/AsDetailComp";

    export default {
        name: "AsDetailForLatest",
        components: {
            AsDetailComp
        },
      props: {
        workorderId: undefined,
      },
      mounted() {
        localStorage.setItem('ACCOUNT_DETAIL_TAB', 2)
      }
    }
</script>
