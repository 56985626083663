<template>
  <v-container
    ref="container"
    class="overflow-y-auto pa-0"
    fluid
    style="border-top: 1px solid #ddd"
  >
    <v-row
      v-for="(item,index) in items"
      :key="item.raw.workorderId"
      class="mx-0"
    >
      <as-item-card
        :item="item"
        :options="asOptions"
      />
    </v-row>
  </v-container>
</template>

<script>
    import AsItemCard from "@/components/items/as/AsItemCard";
    import CodeOptions from "@/components/mixins/CodeOptions";

    export default {
        name: "AsListItemCard",
        components: {AsItemCard},
        mixins: [CodeOptions],
        props: {
            items: {
                type: Array,
                default: () => [],
            },
        },
        created() {
            this.loadOptions();
        },
        mounted() {
            this.loadOptions();
        },
        methods: {
            loadOptions() {
                this.loadOptionsForWorkorderStaff();
                this.loadOptionsForDoneReasonCode()
            },
        }
    }
</script>

<style scoped>
    >>> .row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
</style>
