<template>
  <div style="z-index: 9;">
    <!-- 접수 상태 상태시 -->
    <template v-if="item.isWorkorderStateReceived">
      <!-- 수락 -->
      <as-accept-button
        :callback="() => item.accept().catch()"
        @click:button="buttonClickCallback"
      >
        <template #default="{ on }">
          <v-btn
            fab
            dark
            small
            class="accept-fab-button"
            v-on="on"
          >
            {{ '수락' | translate }}
          </v-btn>
        </template>
      </as-accept-button>
    </template>

    <!-- 기사대기 상태시 -->
    <template v-else-if="item.isWorkorderStateWatingStaff">
      <!-- 작업자 본인일 경우 -->
      <template v-if="itsMe">
        <v-row class="justify-space-between">
          <!-- 예약 -->
          <as-reserve-with-process-modal
            :options="options"
            :item="item"
            :callback="(data) => item.reserveWithProcess(data).catch()"
            @click:button="buttonClickCallback"
          >
            <template #default="{ on }">
              <v-btn
                fab
                dark
                small
                class="reserve-fab-button"
                v-on="on"
              >
                {{ '예약' | translate }}
              </v-btn>
            </template>
          </as-reserve-with-process-modal>
          <!-- 배정 취소 -->
          <as-cancel-assign-button
            :options="options"
            :item="item"
            :callback="(data) => item.assignWithInformation(data).catch()"
            @click:button="buttonClickCallback"
          >
            <template #default="{ on }">
              <v-btn
                fab
                dark
                color="#2A5270"
                small
                class="reserve-fab-button"
                v-on="on"
              >
                {{ '배정 취소' | translate }}
              </v-btn>
            </template>
          </as-cancel-assign-button>
          <!-- 위임 -->
          <staff-delegate-dialog-with-modal
            :exclude="item.raw.worker"
            :options="options"
            :callback="data => item.delegate(data).catch()"
            @click:button="buttonClickCallback"
          >
            <template #default="{ on }">
              <v-btn
                fab
                dark
                small
                class="delegate-fab-button"
                v-on="on"
              >
                {{ '위임' | translate }}
              </v-btn>
            </template>
          </staff-delegate-dialog-with-modal>
          <!-- 수락 -->
          <as-accept-button
            :callback="() => item.accept().catch()"
            @click:button="buttonClickCallback"
          >
            <template #default="{ on }">
              <v-btn
                fab
                dark
                small
                class="accept-fab-button"
                v-on="on"
              >
                {{ '수락' | translate }}
              </v-btn>
            </template>
          </as-accept-button>
          <!-- 취소 -->
          <as-cancel-dialog-with-modal
            :options="options"
            :item="item"
            :callback="data => item.cancel(data).catch()"
            @click:button="buttonClickCallback"
          >
            <template #default="{ on }">
              <v-btn
                fab
                dark
                small
                class="cancel-fab-button"
                v-on="on"
              >
                {{ '취소' | translate }}
              </v-btn>
            </template>
          </as-cancel-dialog-with-modal>
          <!-- 완료 -->
          <as-success-dialog-with-modal
            :item="item"
            :options="options"
            :callback="data => item.complete(data).catch()"
            @click:button="buttonClickCallback"
          >
            <template #default="{ on }">
              <v-btn
                fab
                dark
                small
                class="complete-fab-button"
                v-on="on"
              >
                {{ '완료' | translate }}
              </v-btn>
            </template>
          </as-success-dialog-with-modal>
        </v-row>
      </template>

      <!-- 본인이 아닌 다른 작업자일 경우 -->
      <template v-else />
    </template>

    <!-- 진행 상태시 -->
    <template v-else-if="item.isWorkorderStateInProgress">
      <!-- 작업자 본인일 경우 -->
      <template v-if="itsMe">
        <!-- 예약 -->
        <as-reserve-dialog-with-modal
          :options="options"
          :item="item"
          :callback="(data) => item.reserve(data).catch()"
          @click:button="buttonClickCallback"
        >
          <template #default="{ on }">
            <v-btn
              fab
              dark
              small
              class="reserve-fab-button"
              v-on="on"
            >
              {{ '예약' | translate }}
            </v-btn>
          </template>
        </as-reserve-dialog-with-modal>
        <!-- 배정 취소 -->
        <as-cancel-assign-button
          :options="options"
          :item="item"
          :callback="(data) => item.assignWithInformation(data).catch()"
          @click:button="buttonClickCallback"
        >
          <template #default="{ on }">
            <v-btn
              fab
              dark
              color="#2A5270"
              small
              class="reserve-fab-button"
              v-on="on"
            >
              {{ '배정 취소' | translate }}
            </v-btn>
          </template>
        </as-cancel-assign-button>
        <!-- 위임 -->
        <staff-delegate-dialog-with-modal
          :exclude="item.raw.worker"
          :options="options"
          :callback="data => item.delegate(data).catch()"
          @click:button="buttonClickCallback"
        >
          <template #default="{ on }">
            <v-btn
              fab
              dark
              small
              class="delegate-fab-button"
              v-on="on"
            >
              {{ '위임' | translate }}
            </v-btn>
          </template>
        </staff-delegate-dialog-with-modal>
        <!-- 취소 -->
        <as-cancel-dialog-with-modal
          :options="options"
          :item="item"
          :callback="data => item.cancel(data).catch()"
          @click:button="buttonClickCallback"
        >
          <template #default="{ on }">
            <v-btn
              fab
              dark
              small
              class="cancel-fab-button"
              v-on="on"
            >
              {{ '취소' | translate }}
            </v-btn>
          </template>
        </as-cancel-dialog-with-modal>
        <!-- 완료 -->
        <as-success-dialog-with-modal
          :item="item"
          :options="options"
          :callback="data => item.complete(data).catch()"
          @click:button="buttonClickCallback"
        >
          <template #default="{ on }">
            <v-btn
              fab
              dark
              small
              class="complete-fab-button"
              v-on="on"
            >
              {{ '완료' | translate }}
            </v-btn>
          </template>
        </as-success-dialog-with-modal>
      </template>

      <!-- 본인이 아닌 다른 작업자일 경우 -->
      <template v-else />
    </template>

    <!-- 취소 상태시 -->
    <template v-else-if="item.isWorkorderStateCanceled">
      <as-clone-button
        :item="item"
        replace
        @click:button="buttonClickCallback"
      >
        <template #default="{ on }">
          <v-btn
            fab
            dark
            small
            class="clone-fab-button"
            v-on="on"
          >
            {{ '재등록' | translate }}
          </v-btn>
        </template>
      </as-clone-button>
    </template>
    <!-- 완료 상태시 -->
    <template v-else-if="item.isWorkorderStateCompleted">
      <!-- 재서명 -->
      <!--      <as-success-dialog-with-modal-->
      <!--        resign-->
      <!--        :item="item"-->
      <!--        :options="options"-->
      <!--        :callback="data => item.complete(data).catch()"-->
      <!--        @click:button="buttonClickCallback"-->
      <!--      >-->
      <!--        <template #default="{ on }">-->
      <!--          <v-btn-->
      <!--            fab-->
      <!--            dark-->
      <!--            small-->
      <!--            class="complete-resign-button"-->
      <!--            v-on="on"-->
      <!--          >-->
      <!--            {{ '재서명' | translate }}-->
      <!--          </v-btn>-->
      <!--        </template>-->
      <!--      </as-success-dialog-with-modal>-->
      <as-clone-button
        :item="item"
        replace
        @click:button="buttonClickCallback"
      >
        <template #default="{ on }">
          <v-btn
            fab
            dark
            small
            class="clone-fab-button"
            v-on="on"
          >
            {{ '재등록' | translate }}
          </v-btn>
        </template>
      </as-clone-button>
    </template>
  </div>
</template>

<script>
    import Work from "@/model/work";
    import AsAcceptButton from "@/components/part/as/AsAcceptButton";
    import AsCloneButton from "@/components/part/as/AsCloneButton";
    import AsSuccessDialogWithModal from "@/components/part/as/button-with-modal/AsSuccessDialogWithModal";
    import AsCancelDialogWithModal from "@/components/part/as/button-with-modal/AsCancelDialogWithModal";
    import StaffDelegateDialogWithModal from "@/components/part/as/button-with-modal/StaffDelegateDialogWithModal";
    import AsReserveDialogWithModal from "@/components/part/as/button-with-modal/AsReserveDialogWithModal";
    import AsReserveWithProcessModal from "@/components/part/as/button-with-modal/AsReserveWithProcessModal";
    import AsCancelAssignButton from "@/components/part/as/AsCancelAssignButton";

    export default {
        name: "AsSpeedDialogForWorker",
        components: {
            AsReserveWithProcessModal,
            AsReserveDialogWithModal,
            StaffDelegateDialogWithModal,
            AsCancelDialogWithModal, AsSuccessDialogWithModal, AsCloneButton, AsAcceptButton, AsCancelAssignButton
        },
        props: {
            item: {
                type: Work,
                default: () => {
                },
            },
            options: {
                type: Object, default: () => {
                    return {}
                }
            },
            buttonClickCallback: {
                type: Function,
                default: undefined,
            },
        },
        computed: {
            itsMe() {
                return this.item.raw.worker === this.user.userId;
            },
        },
    }
</script>
