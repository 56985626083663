<template>
  <as-detail-comp :workorder-id="workorderId" />
</template>

<script>
    import AsDetailComp from "@/components/windows/as/AsDetailComp";

    export default {
        name: "AsDetail",
        components: {
            AsDetailComp
        },
      props: {
        workorderId: undefined,
      },
    }
</script>
