<template>
  <div
    id="as_register_wrap_con"
    class="overflow-y-auto overflow-x-hidden"
  >
    <my-tool-bar
      :loading="loadingUpdate || loading"
      :action-mode="false"
      :title="title"
    >
      <template #buttons>
        <toolbar-save-action
          @save="onSave"
          @refresh="onRefreshClicked"
        />
      </template>
    </my-tool-bar>

    <div style="position: relative; top: 56px;">
      <template v-if="!loading">
        <m-form
          :key="key"
          ref="form"
          :edit="true"
          :form-data.sync="formData"
          :form-info="formInfo"
          class="overflow-y-auto overflow-x-hidden"
          form-provider="mps"
        />
      </template>
      <template v-else>
        <progress-loading />
      </template>

      <!-- 접수와 동시에 완료 정보 입력 컴포넌트 -->
      <as-auto-success-component
        id="as-auto-success-component"
        ref="autoSuccessComp"
        :is-use.sync="isAutoSuccess"
        :item.sync="asAutoSuccess"
      />
    </div>
    <over-max-size
      :base-param="formData"
      :detachments-list="[]"
      :attachments-list="[requestFileData.memoAttachments, requestFileData.workorderContentAttachments]"
    />
  </div>
</template>

<script>
    import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
    import MyToolBar from "@/components/app/toolbar/MyToolBar";
    import MForm from "@/assets/plugins/mps-form/MForm";
    import {CODE, MAXLENGTH, PATH} from "@/constants";
    import VuexProps from "@/framework/vuex/VuexProps";
    import DetailUtil from "@/components/mixins/DetailUtil";
    import GroupHeader from "@/assets/plugins/mps-form/providers/components/group/GroupHeader";
    import ToolbarSaveAction from "@/components/app/toolbar/ToolbarSaveAction";
    import now from "@/assets/plugins/mps-common/time/now";
    import REGEX from "@/constants/regex";
    import {ETC} from "@/constants/etc-code";
    import ProgressLoading from "@/components/part/ProgressLoading";
    import AsRegisterNewAccountButton from "@/assets/plugins/mps-form/providers/components/AsRegisterNewAccountButton";
    import AsAutoSuccessComponent from "./AsAutoSuccessComponent.vue";
    import OverMaxSize from "@/components/app/toolbar/OverMaxSize.vue";


    const infoKeys = {
        account: "accountId",
        branch: "branchId",
        printer: "printerModelName",
        customerName: "customerName",
        customerMobileno: "customerMobileno",
        customerTelno: "customerTelno",
        customerEmail: "customerEmail",
        asReceiptType: "asReceiptType",
        reasonCode: "reasonCode",
        doneReasonCode: "doneReasonCode",
        staffUId: "staffUId",
        workorderState: "workorderState",
        workorderReceiptDate: "workorderReceiptDate",
        workorderReceiveType: "workorderReceiveType",
        reservationDatetime: "reservationDatetime",
        doneDate: "doneDate",
        address: "address",
        addressDetail: "addressDetail",
        workorderContent: "workorderContent",
        memo: "memo",
        asContent: "asContent",
        receiptionistName: "receiptionistName",
        partnerName: "partnerName",
    };

    export default {
        name: "AsRegisterComp",
        components: {
          OverMaxSize,
          AsAutoSuccessComponent,
            ProgressLoading,
            ToolbarSaveAction,
            MForm, MyToolBar
        },
        extends: WindowComponent,
        mixins: [VuexProps, DetailUtil],
        props: {
            workorderId: undefined,
            staffId: undefined,
          account:undefined,
        },
        data() {
            return {
                key: 0,
                loading: false,
                loadingUpdate: false,
                // Form 컴포넌트의 더티 체크를 피하기 위해 toggle 을 두어 초기화 시킬 때마다 값을 변경한다.
                toggleUpdate: false,
                requestFileData: {
                    workorderContentAttachments: null,
                    memoAttachments: null,
                },
                formData: undefined,
                item: undefined,
                formInfo: [
                    {
                        component: GroupHeader,
                        propsData: {
                            title: this.$translate("A/S 정보"),
                        },
                    },
                    {
                        component: AsRegisterNewAccountButton,
                        read: (data) => data.isNewAccount,
                        update: (data, v) => {
                            data.isNewAccount = v;
                            this.initFormData(v);
                        },
                    },



                    {
                        mutable: true,
                        title: this.$translate('거래처명'),
                        key: infoKeys.account,
                        // disable: (inEditMode, data) => data.isNewAccount,
                        read: (inEditMode, data) => null,
                        write: (data, v) => {
                            if (!v) return;
                            if(!v.accountId && data.isSelfChargeEmployee !== v.isSelfCharge) {
                              data.isSelfChargeEmployee = v.isSelfCharge
                              return
                            }
                            data.accountId = v.accountId;
                            data.accountName = v.accountName;

                            data.accountBranchId = null;
                            data.branchName = null;
                            data.printerModelName = null;
                            data.printerSerialNo = null;
                            data.monitoringPrinterId = null;
                            data.address = v.address;
                            data.zipCode = v.zipCode;
                            data.addressDetail = v.addressDetail;
                            data.customerName = v.chargeFullname;
                            data.customerMobileno = v.chargeMobileno;
                            data.customerTelno = v.chargeTelno;
                            data.customerEmail = v.chargeEmail;
                          data.lat =  v.lat;
                          data.lng =  v.lng;
                        },
                        formReadType: this.$mps.form.types.ACCOUNT_PICK_OR_WRITER,
                        formWriteType: this.$mps.form.types.ACCOUNT_PICK_OR_WRITER,
                        rules: [
                            (data) => (data.isNewAccount || !!data.accountId) || this.$translate('거래처를 선택해 주세요'),         // 거래처 선택 Rule
                        ],
                        options: {
                          maxlength: MAXLENGTH.ACCOUNT.NAME,
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate('영업점명'),
                        key: infoKeys.branch,
                        disable: (inEditMode, data) => !data.accountId,
                        read: (inEditMode, data) => null,
                        write: (data, v) => {
                            if (!v) return;
                            data.accountBranchId = v.accountBranchId;
                            data.branchName = v.branchName;
                            data.address = v.address;
                            data.zipCode = v.zipCode;
                            data.addressDetail = v.addressDetail;
                            data.customerName = v.chargeFullname;
                            data.customerMobileno = v.chargeMobileno;
                            data.customerTelno = v.chargeTelno;
                            data.customerEmail = v.chargeEmail;
                            data.printerModelName = null;
                            data.printerSerialNo = null;
                            data.monitoringPrinterId = null;
                          data.lat =  v.lat;
                          data.lng =  v.lng;
                        },
                        formReadType: this.$mps.form.types.BRANCH_PICKER,
                        formWriteType: this.$mps.form.types.BRANCH_PICKER,
                    },
                    {
                        mutable: true,
                        title: this.$translate('프린터 모델'),
                        key: infoKeys.printer,
                        disable: (inEditMode, data) => !data.accountId  && !data.isNewAccount,
                        read: (inEditMode, data) => inEditMode ? null : data.printerModelName,
                        write: (data, v) => {
                            if (!v) return;
                            data.printerModelName = v.printerModelName;
                            data.printerSerialNo = v.printerSerialNo;
                            data.monitoringPrinterId = v.monitoringPrinterId;
                        },
                        formReadType: this.$mps.form.types.PRINTER_PICKER,
                        formWriteType: this.$mps.form.types.PRINTER_PICKER,
                        rules: [
                            // (data) => !!data.printerModelName || this.$translate('프린터 모델을 입력해 주세요'),
                        ],
                    },
                    {
                        mutable: true,
                        title: this.$translate('접수 유형'),
                        key: infoKeys.workorderReceiveType,
                        read: (inEditMode, data) => {
                          return {
                              codeValue: CODE.WORKORDER_RECEIVE_TYPE_NAME[data.workorderReceiveType] || CODE.WORKORDER_RECEIVE_TYPE_NAME.NULL,
                              codeKey: data.workorderReceiveType,
                          }
                        },
                        write: (data, v) => {
                            if (!v) return;
                            data.workorderReceiveType = v.codeKey;
                        },
                        disable: (inEditMode, data) => data.isNewAccount,
                        formReadType: this.$mps.form.types.OPTIONS_PICKER,
                        formWriteType: this.$mps.form.types.OPTIONS_PICKER,
                        options: {
                          items: CODE.WORKORDER_RECEIVE_TYPE_ITEMS,
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate('AS_기사'),
                        key: infoKeys.staffUId,
                        read: (inEditMode, data) => {
                            return {
                                userId: data.staffUId || data.worker,
                                userFullname: data.staffName
                            }
                        },
                        write: (data, v) => {
                            if (!v) return;
                            data.staffName = v.userFullname;
                            data.staffUId = v.userId;
                        },
                        formReadType: this.$mps.form.types.STAFF_PICKER,
                        formWriteType: this.$mps.form.types.STAFF_PICKER,
                    },
                    {
                        mutable: true,
                        title: this.$translate('예약일시'),
                        key: infoKeys.reservationDatetime,
                        write: (data, v) => {
                            const d = this.$parseDate(v);
                            data.reservationDatetime = !!d ? d.toISO() : null
                        },
                        formReadType: this.$mps.form.types.DATETIME_PICKER,
                        formWriteType: this.$mps.form.types.DATETIME_PICKER,
                        options: {
                            canNotSelectPast: true,
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate('증상 상세'),
                        key: infoKeys.workorderContent,
                        formReadType: this.$mps.form.types.FILE_UPLOAD,
                        formWriteType: this.$mps.form.types.FILE_UPLOAD,
                        updateData: (v) => {
                            this.requestFileData.workorderContentAttachments = v.attachments;
                        },
                        options: {
                            listType: ETC.MULTIFILE_FORM_LIST_TYPE.IMAGE_TYPE,
                            accept: ETC.ACCEPT.IMAGE,
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate('주소'),
                        key: infoKeys.address,
                        read: (inEditMode, data) => null,
                        write: (data, v) => {
                            if (!v) return;
                            data.address = v.roadAddr;
                            data.addressDetail = null;
                            data.zipCode = v.zipNo;
                            data.lat = v.lat;
                            data.lng = v.lng;
                        },
                        formReadType: this.$mps.form.types.ADDRESS_PICKER,
                        formWriteType: this.$mps.form.types.ADDRESS_PICKER,
                    },
                    {
                        mutable: true,
                        title: this.$translate('상세주소'),
                        key: infoKeys.addressDetail,
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {
                            maxlength: MAXLENGTH.ADDRESS.DETAIL,
                        },
                    },
                    {
                        component: GroupHeader,
                        propsData: {
                            title: this.$translate("A/S 상세"),
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate('AS접수_구분'),
                        key: infoKeys.asReceiptType,
                        read: (inEditMode, data) => inEditMode ? {codeKey: data.asReceiptType} : null,
                        write: (data, v) => {
                            if (!v) return;
                            data.asReceiptType = v.codeKey;
                        },
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.RENTALCOMPANY_CODE_PICKER,
                        options: {
                            codeType: "ART",
                        },
                        rules: [
                            // (data) => !!data.asReceiptType || this.$translate('AS접수 구분을 선택해 주세요'),
                        ],
                    },
                    {
                        mutable: true,
                        title: this.$translate('AS접수_증상'),
                        key: infoKeys.reasonCode,
                        read: (inEditMode, data) => inEditMode ? {codeKey: data.reasonCode} : null,
                        write: (data, v) => {
                            if (!v) return;
                            data.reasonCode = v.codeKey;
                        },
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.RENTALCOMPANY_CODE_PICKER,
                        options: {
                            codeType: "AC",
                        },
                        rules: [],
                    },
                    {
                        mutable: true,
                        title: this.$translate('접수일시'),
                        key: infoKeys.workorderReceiptDate,
                        write: (data, v) => {
                            const d = this.$parseDate(v);
                            data.workorderReceiptDate = !!d ? d.toISO() : null
                        },
                        formReadType: this.$mps.form.types.DATETIME_PICKER,
                        formWriteType: this.$mps.form.types.DATETIME_PICKER,
                        rules: [
                            (data) => !!data.workorderReceiptDate || this.$translate('접수일시를 입력해 주세요'),
                        ],
                    },
                    {
                        mutable: true,
                        title: this.$translate('협력업체'),
                        key: infoKeys.partnerName,
                        read: (inEditMode, data) => null,
                        write: (data, v) => {
                            if (!v) return;
                            data.partnerName = v.codeValue;
                        },
                        formReadType: this.$mps.form.types.RENTALCOMPANY_CODE_DIALOG_PICKER,
                        formWriteType: this.$mps.form.types.RENTALCOMPANY_CODE_DIALOG_PICKER,
                    },
                    {
                        mutable: true,
                        title: this.$translate('메모'),
                        key: infoKeys.memo,
                        formReadType: this.$mps.form.types.FILE_UPLOAD,
                        formWriteType: this.$mps.form.types.FILE_UPLOAD,
                        updateData: (v) => {
                            this.requestFileData.memoAttachments = v.attachments;
                        },
                        options: {
                            listType: ETC.MULTIFILE_FORM_LIST_TYPE.IMAGE_TYPE,
                            accept: ETC.ACCEPT.IMAGE,
                        }
                    },
                    {
                        component: GroupHeader,
                        propsData: {
                            title: this.$translate("거래처 담당자"),
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate('담당자'),
                        key: infoKeys.customerName,
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {
                            maxlength: MAXLENGTH.WORK.CUSTOMER.NAME,
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate('휴대폰'),
                        key: infoKeys.customerMobileno,
                        formReadType: this.$mps.form.types.PHONE_NUMBER,
                        formWriteType: this.$mps.form.types.PHONE_NUMBER,
                        options: {
                            maxlength: MAXLENGTH.WORK.CUSTOMER.MOBILENO,
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate('전화번호'),
                        key: infoKeys.customerTelno,
                        formReadType: this.$mps.form.types.PHONE_NUMBER,
                        formWriteType: this.$mps.form.types.PHONE_NUMBER,
                        options: {
                            maxlength: MAXLENGTH.WORK.CUSTOMER.TELNO,
                        },
                    },
                    {
                        mutable: true,
                        title: this.$translate('담당자 이메일'),
                        key: infoKeys.customerEmail,
                        formReadType: this.$mps.form.types.TEXTAREA,
                        formWriteType: this.$mps.form.types.TEXTAREA,
                        options: {
                            type: "email",
                            maxlength: MAXLENGTH.WORK.CUSTOMER.EMAIL,
                        },
                        rules: [
                            (data) => !data.customerEmail || RegExp(REGEX.EMAIL).test(data.customerEmail) || this.$translate('올바른 이메일을 입력해주세요'),
                        ],
                    },/*
                    {
                        component: Spacer,
                        propsData: {
                            marginBottom: "50px",
                        }
                    },*/
                ],

              isAutoSuccess: false,
              // 완료 처리
              asAutoSuccess: {
                doneDate: now(),
                asContent: null,
                doneReasonCode: null,
              },
            }
        },
        watch: {
            formData: {
                deep: true,
                handler: function () {
                    this.updateOptions();

                }
            },
          isAutoSuccess(newValue){
            if(newValue){
              this.scrollToAutoSuccess()
            }
          },
          "formData.isSelfChargeEmployee"(newValue) {
              if(this.formData.isNewAccount && newValue) {
                this.formData.staffUId = this.user.userId
              } else {
                this.formData.staffUId = null
              }
          }
        },
        mounted() {
            this.key++;
            this.initFormData();
            this.updateOptions();
            if (this.workorderId) {
                this.clone();
            }
            if (this.staffId) {
                this.formData.staffUId = this.staffId
            }
            if(this.account){
              this.cloneAccountData(this.account)
            }
            this.scrollToTop();
        },
        methods: {
            scrollToTop() {
              window.document.getElementById('as_register_wrap_con').scrollTo(0,0)
            },
            updateOptions() {
                // 거래처 피커
                this.updateOptionByKey(infoKeys.account, () => {
                    return {
                        accountId: this.formData.accountId,
                        accountName: this.formData.accountName,
                        lat: this.formData.lat,
                        lng: this.formData.lng,
                    }
                });

                // 영업점 피커
                this.updateOptionByKey(infoKeys.branch, () => {
                    return {
                        accountId: this.formData.accountId,
                        accountName: this.formData.accountName,
                        branchName: this.formData.branchName,
                        accountBranchId: this.formData.accountBranchId,
                    }
                });

                // 프린터 피커
                this.updateOptionByKey(infoKeys.printer, () => {
                    return {
                        printerModelName: this.formData.printerModelName,
                        printerSerialNo: this.formData.printerSerialNo,
                        accountId: this.formData.accountId,
                        accountBranchId: this.formData.accountBranchId,
                        whetherLoadPrinterModels: true,
                        whetherLoadPrinters: true,
                        whetherLoadUserModels: true,
                    }
                });

                // 주소 피커
                this.updateOptionByKey(infoKeys.address, () => {
                    return {
                        address: this.formData.address,
                        zipCode: this.formData.zipCode,
                        lat: this.formData.lat,
                        lng: this.formData.lng,
                    }
                });

                // 협력업체 피커
                this.updateOptionByKey(infoKeys.partnerName, () => {
                    return {
                        codeType: 'ASPTN',
                        codeValue: this.formData.partnerName,
                        defaultTitle: "협력업체 선택",
                    }
                });
            },

            /**
             * A/S 등록후 첨부파일을 초기화한다.
             */
            resetOptionsForFileUploadForm() {
                this.requestFileData.workorderContentAttachments = null;
                this.requestFileData.memoAttachments = null;

                // 접수증상 상세
                this.updateOptionByKey(infoKeys.workorderContent, () => {
                    return {
                        attachments: this.toggleUpdate,
                        listType: ETC.MULTIFILE_FORM_LIST_TYPE.IMAGE_TYPE,
                        accept: ETC.ACCEPT.IMAGE,
                    }
                });

                // 메모
                this.updateOptionByKey(infoKeys.memo, () => {
                    return {
                        attachments: this.toggleUpdate,
                        listType: ETC.MULTIFILE_FORM_LIST_TYPE.IMAGE_TYPE,
                        accept: ETC.ACCEPT.IMAGE,
                    }
                });

                this.toggleUpdate = !this.toggleUpdate;
            },

            async requestSave() {
                this.loadingUpdate = true;
                try {
                    const response = await this.$requestWithOverlay(PATH.WORK_ORDER.REGISTER_NEW)
                        .multipart()
                        .appendObject('context', {
                          ...this.formData,
                          workorderReceiveType: this.formData.isNewAccount ? 'N' : (this.formData.workorderReceiveType),
                        })
                        .appendFiles("workorderContentAttachments", this.requestFileData.workorderContentAttachments)
                        .appendFiles("memoAttachments", this.requestFileData.memoAttachments)
                        .enqueue();
                    this.resetOptionsForFileUploadForm();
                    this.$snackbar(this.$translate("A/S가 등록되었습니다."));
                    this.initFormData();
                    this.routerManager.back();
                } catch (e) {
                    this.$snackbarError(this.$translate("서버에서 에러가 발생하였습니다."));
                }
                this.loadingUpdate = false;
            },

            /**
             * 등록과 함께 완료 처리
             */
            async requestRegisterAndSuccess() {
                // 저장 전 검사
                if (!this.validateAutoSuccess()) return

                this.loadingUpdate = true;
                try {
                  // 서명을 가져온다.
                  const signature = this.$refs.autoSuccessComp.getSignature()

                  const params = {
                    // A/S 등록 정보
                    ...this.formData,
                    workorderReceiveType: this.formData.isNewAccount ? 'N' : (this.formData.workorderReceiveType),
                    // 완료시간 : 현재
                    doneDate: now().toISO(),
                    // 완료 처리 사유 코드
                    doneReasonCode: this.asAutoSuccess.doneReasonCode,
                    // 완료처리 내용
                    asContent: this.asAutoSuccess.asContent,
                    workorderState: CODE.AS.COMPLETED,
                  }

                  // 서명 이미지명은 임시로 지정하고 실제 이미지명은 서버에서 지정합니다.
                  // 서명 이미지명 형식은 "signpad-workorderId-asId.png" 이기 때문에 접수되지 않은 현 시점에서 지정할 수 없습니다.
                  const signImageName = 'signpad-temp-' + now().toFormat('yyMMdd_HHmmss') + '.png'

                  const response = await this.$requestWithOverlay(PATH.WORK_ORDER.AS_REGISTER_AND_SUCCESS)
                      .multipart()
                      .appendObject('context', params)
                      .appendDataUri("signImage", signature, signImageName)
                      .appendFiles("workorderContentAttachments", this.requestFileData.workorderContentAttachments)
                      .appendFiles("memoAttachments", this.requestFileData.memoAttachments)
                      .enqueue();
                  this.resetOptionsForFileUploadForm();
                  this.$snackbar(this.$translate("A/S가 접수와 동시에 완료 처리되었습니다."));
                  this.initFormData();
                  this.routerManager.back();
                } catch (e) {
                    this.$snackbarError(this.$translate("서버에서 에러가 발생하였습니다."));
                }
                this.loadingUpdate = false;
            },

            /**
             * '접수 동시에 완료' 저장 전 검사 메서드
             * @returns {boolean} F: 실패 / T: 성공
             */
            validateAutoSuccess() {
              if (!this.asAutoSuccess.asContent) {
                this.$snackbarError(this.$translate('완료 처리 내역을 입력해 주세요'))
                return false
              }
              if (!this.asAutoSuccess.doneReasonCode) {
                this.$snackbarError(this.$translate('처리유형을 입력해 주세요'))
                return false
              }
              return true
            },

            async onSave() {
                if (!await this.$refs.form.validate()) return;

                if(this.isAutoSuccess){
                  this.requestRegisterAndSuccess()
                }else{
                  this.requestSave()
                }
            },

            initFormData(isNewAccount = false) {
              this.clearFormData(isNewAccount)
              // 접수 동시에 완료 비활성화 및 초기화
              this.isAutoSuccess = false
              this.$refs.autoSuccessComp.clearData()
            },

            async clone() {
                this.loading = true;
                try {
                    const response = await this.$request(PATH.WORK_ORDER.DETAIL)
                        .setObject({workorderId: this.workorderId})
                        .catch()
                        .wait(500)
                        .enqueue();
                    this.cloneFormData(response);
                } catch (e) {
                    this.$snackbarError(this.$translate("데이터를 가져오지 못했습니다."));
                }
                this.loading = false;
            },

            cloneFormData(item) {
                this.formData = {
                    isNewAccount: false,
                    isSelfChargeEmployee: false,
                    accountId: item.accountId,
                    accountName: item.accountName,
                    accountBranchId: item.accountBranchId,
                    branchName: item.branchName,
                    printerModelName: item.printerModelName,
                    printerSerialNo: item.printerSerialNo,
                    monitoringPrinterId: item.monitoringPrinterId,
                    address: item.address,
                    zipCode: item.zipCode,
                    addressDetail: item.addressDetail,
                    staffName: null,
                    staffUId: null,
                    customerName: item.customerName,
                    customerMobileno: item.customerMobileno,
                    customerTelno: item.customerTelno,
                    customerEmail: item.customerEmail,
                    asReceiptType: item.asReceiptType,
                    reasonCode: item.reasonCode,
                    asReceiptTypeName: item.asReceiptTypeName,
                    reasonCodeName: item.reasonCodeName,
                    workorderReceiptDate: now().toISO(),
                    workorderReceiveType: item.workorderReceiveType,
                    reservationDatetime: null,
                    workorderContent: item.workorderContent,
                    memo: item.memo,
                    partnerName: item.partnerName,
                };
            },

            clearFormData(isNewAccount) {
                this.formData = {
                    isNewAccount: isNewAccount,
                    isSelfChargeEmployee: false,
                    accountId: null,
                    accountName: null,
                    accountBranchId: null,
                    branchName: null,
                    printerModelName: null,
                    printerSerialNo: null,
                    monitoringPrinterId: null,
                    address: null,
                    zipCode: null,
                    addressDetail: null,
                    staffName: null,
                    staffUId: null,
                    customerName: null,
                    customerMobileno: null,
                    customerTelno: null,
                    customerEmail: null,
                    asReceiptType: null,
                    reasonCode: null,
                    asReceiptTypeName: null,
                    reasonCodeName: null,
                    workorderReceiptDate: now().toISO(),
                    workorderReceiveType: '0',
                    reservationDatetime: null,
                    workorderContent: null,
                    memo: null,
                    partnerName: null,
                };
            },

          cloneAccountData(v){
            this.formData = {
              accountId : v.account? v.account.accountId : v.accountId,
              accountName : v.account? v.account.accountName  : v.accountName,
              accountBranchId : null,
              branchName : null,
              printerModelName : null,
              printerSerialNo : null,
              monitoringPrinterId : null,
              address :v.company?  v.company.address  : v.address,
              zipCode :v.company?  v.company.zipCode  : v.zipCode,
              addressDetail : v.company? v.company.addressDetail  : v.addressDetail,
              customerName : v.chargeDetail? v.chargeDetail.userFullname  : v.userFullname,
              customerMobileno :v.chargeDetail?  v.chargeDetail.userMobileno  : v.chargeMobileno,
              customerTelno : v.chargeDetail? v.chargeDetail.userTelno : v.chargeTelno,
              customerEmail :v.chargeDetail?  v.chargeDetail.userEmail : v.chargeEmail,
              workorderReceiptDate: now().toISO(),
              workorderReceiveType: '0',
              lat: v.account? v.account.lat  : v.lat,
              lng: v.account? v.account.lng  : v.lng,
              isNewAccount: false,
              // isSelfChargeEmployee: false,
              staffName: null,
              staffUId: null,
              asReceiptType: null,
              reasonCode: null,
              asReceiptTypeName: null,
              reasonCodeName: null,
              reservationDatetime: null,
              workorderContent: null,
              memo: null,
              partnerName: null,
            }
          },

            onRefreshClicked() {
                this.$dialog()
                    .message(this.$translate('편집중인 정보를 초기화합니다.'))
                    .buttonNegative(this.$translate('취소'))
                    .buttonPositive(this.$translate('초기화'), () => {
                        this.clearFormData();
                    })
                    .build().show();
            },

          /**
           * A/S 접수 동시에 완료 컴포넌트로 스크롤 하는 기능
           */
          scrollToAutoSuccess() {
            this.$nextTick(() => {
              window.document.getElementById('as_register_wrap_con')
                  .scrollTo({
                    top: window.document.getElementById('as-auto-success-component').offsetTop,
                    behavior: 'smooth',
                  })

            })
          },
        }
    }
</script>

<style scoped>
</style>
