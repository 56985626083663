<template>
  <v-container
    ref="container"
    class="overflow-y-auto py-0 px-0 fill-height align-start"
    fluid
  >
    <template v-if="!loading">
      <template v-if="items.length > 0">
        <as-history-list-item :items="items" />
      </template>
      <template v-else>
        <no-items
          :title="'A/S 변경내역이 없습니다' | translate"
        />
      </template>
    </template>
    <template v-else>
      <div style="display: flex; align-items: center; justify-content: center; height: 100%; width: 100%;">
        <progress-loading />
      </div>
    </template>
  </v-container>
</template>

<script>
    import {PATH} from "@/constants";
    import NoItems from "@/components/items/NoItems";
    import WorkorderHistory from "@/model/workorder-history";
    import AsHistoryListItem from "@/components/items/as/AsHistoryListItem";
    import ProgressLoading from "@/components/part/ProgressLoading";

    export default {
        name: "AsHistoryTabsItems",
        components: {ProgressLoading, AsHistoryListItem, NoItems},
        props: {
          workorderId: {
            type: Number,
            default: undefined,
          },
          changeId: {
            type: Number,
            default: 0,
          },
        },
        data() {
            return {
                loading: false,
                items: [],
            };
        },
        watch: {
            changeId() {
                this.loadAsHistoryChanged();
            },
        },
        created() {
            this.loadAsHistoryChanged();
        },
        methods: {
            async loadAsHistoryChanged(loading = true) {
                if (!this.workorderId) return;
                if (this.loading) return;
                this.loading = loading;
                try {
                    const response = await this.$request(PATH.WORK_ORDER.HISTORY)
                        .setObject({workorderId: this.workorderId})
                            .wait(500)
                        .enqueue();
                    this.items = response.data.map(item => new WorkorderHistory(item));
                } catch (error) {
                    console.error(error);
                }
                this.loading = false;
            },

        },
    }
</script>

<style scoped>

</style>