<template>
  <v-list
    three-line
    flat
    class="px-2 py-0"
    style="height: 100%; overflow-y: auto !important;"
  >
    <v-list-item-group class="pb-12">
      <div
        v-for="(item,index) in items"
        :key="item.raw.workorderStateHistoryId"
      >
        <v-list-item
          v-ripple="false"
          class="px-0"
        >
          <v-list-item-content>
            <v-list-item-subtitle>
              <v-row
                justify="space-between"
                class="mx-0 black--text px__18 font__16"
              >
                <div>{{ '변경일' | translate }}: {{ item.raw.regDatetime | datetime }}</div>
                <div :class="{ color__blue : item.isWorkorderStateCompleted, color__red: item.isWorkorderStateCanceled }">
                  {{ item.raw.workorderStateName }}
                </div>
              </v-row>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-row
                justify="space-between"
                class="mx-0 black--text px__18"
              >
                <div>
                  {{ '작성자' | translate }} : {{ item.raw.registerName }}
                </div>
              </v-row>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-row
                justify="space-between"
                class="mx-0 black--text px__18"
              >
                <div>
                  {{ 'AS 기사' | translate }} : {{ item.raw.workerName }}
                </div>
              </v-row>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="!!item.raw.changedReason">
              <v-row
                justify="space-between"
                class="mx-0 color__blue px__18"
              >
                <div class="color__blue">
                  <span>
                    {{ '사유' | translate }} : {{ item.raw.changedReason }}
                  </span>
                </div>
              </v-row>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
      </div>
    </v-list-item-group>
  </v-list>
</template>

<script>
    export default {
        name: "AsHistoryListItem",
        components: {},
        props: {
            items: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
            }
        },
        methods: {}
    }
</script>

<style scoped>

</style>