<template>
  <as-register-comp :workorder-id="workorderId" />
</template>

<script>
    import AsRegisterComp from "@/components/windows/as/AsRegisterComp";

    export default {
        name: "AsReRegister",
        components: {AsRegisterComp},
        props: {
            workorderId: undefined,
        },
    }
</script>

<style scoped>
</style>