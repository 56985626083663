<script>
    import ServerFile from "@/model/server-file";
    import Attachment from "@/model/attachment";

    export default {
        name: "AsMultiFileUploadMixin",
        data() {
            return {
                attachments: {
                    workorderContentAttachments: [],
                    asContentAttachments: [],
                    memoAttachments: [],
                    customerSign: [],
                },
            };
        },
        methods: {
            /**
             * 첨부파일 배열을 초기화 합니다.
             */
            resetAttachments() {
                this.attachments.workorderContentAttachments.splice(0, this.attachments.workorderContentAttachments.length);
                this.attachments.asContentAttachments.splice(0, this.attachments.asContentAttachments.length);
                this.attachments.memoAttachments.splice(0, this.attachments.memoAttachments.length);
                this.attachments.customerSign.splice(0, this.attachments.customerSign.length);
            },

            /**
             * 서버에서 온 데이터에서 파일을 추출하여 모델화한다.
             */
            toAttachments(context) {
                this.resetAttachments();

                // workorderContent
                if (!!context.workorderContentAttachments && context.workorderContentAttachments.length > 0) {
                    context.workorderContentAttachments.forEach(e => {
                        let sf = new ServerFile(e);
                        const attachment = new Attachment(sf);
                        this.attachments.workorderContentAttachments.push(attachment);
                    });
                }
                // asContent
                if (!!context.asContentAttachments && context.asContentAttachments.length > 0) {
                    context.asContentAttachments.forEach(e => {
                        let sf = new ServerFile(e);
                        const attachment = new Attachment(sf);
                        this.attachments.asContentAttachments.push(attachment);
                    });
                }
                // memo
                if (!!context.memoAttachments && context.memoAttachments.length > 0) {
                    context.memoAttachments.forEach(e => {
                        let sf = new ServerFile(e);
                        const attachment = new Attachment(sf);
                        this.attachments.memoAttachments.push(attachment);
                    });
                }

                // 고객 서명
                if (!!context.customerSign) {
                    let sf = new ServerFile({
                        originalFilename: this.$translate("고객 서명"),
                        uploadPath: context.customerSign,
                        path: context.customerSign,
                    });
                    const attachment = new Attachment(sf);
                    this.attachments.customerSign.push(attachment);
                }
            },

            /**
             * 편집모드에서 서버로 전송할 첨부 데이터를 만듭니다.
             *
             * 여기에는 삭제될 파일(Dettachments)
             * 새로 추가될 파일(Attachments) 가 있습니다.
             *
             * 기존 파일은 서버로 따로 보내지 않습니다.
             *
             */
            makeAttachmenetsAndDettachmentsToRequestServer() {
                const workorderContentFileData = this.makeAttachmenetsAndDettachmentsToRequestServerForWorkorderContent();
                const asContentFileData = this.makeAttachmenetsAndDettachmentsToRequestServerForAsContent();
                const memoContentFileData = this.makeAttachmenetsAndDettachmentsToRequestServerForMemo();

                return {
                    workorderContentAttachments: workorderContentFileData.attachments,
                    workorderContentDettachments: workorderContentFileData.dettachments,
                    asContentAttachments: asContentFileData.attachments,
                    asContentDettachments: asContentFileData.dettachments,
                    memoContentAttachments: memoContentFileData.attachments,
                    memoDettachments: memoContentFileData.dettachments,
                };
            },

            makeAttachmenetsAndDettachmentsToRequestServerForWorkorderContent(files, attaches) {
                return this.makeAttachmentsAndDettachmentsContext(files, attaches);
            },

            makeAttachmenetsAndDettachmentsToRequestServerForAsContent(files, attaches) {
                return this.makeAttachmentsAndDettachmentsContext(files, attaches);
            },

            makeAttachmenetsAndDettachmentsToRequestServerForMemo(files, attaches) {
                return this.makeAttachmentsAndDettachmentsContext(files, attaches);
            },

            makeAttachmentsAndDettachmentsContext(files, attaches) {
                const attachments = (files || []);
                const dettachments = (attaches || []).filter(e => e.dettached).map(e => e.serverFile).filter(e => !!e);

                return {
                    attachments: attachments,
                    dettachments: dettachments,
                }
            },
        }
    }
</script>

<style scoped>

</style>
