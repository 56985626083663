<template>
  <div>
    <!-- 인피니트 리스트 -->
    <vue-infinite-loader
      :items="items"
      :fetch="fetch"
      :identifier="identifier"
      item-key="raw.workorderId"
    >
      <template #default="{ item }">
        <as-item-card
          :item="item"
          :options="asOptions"
        />
      </template>
    </vue-infinite-loader>

    <!-- 추가 버튼 -->
    <add-button
      style="position: fixed; left: 10px; bottom: 65px;"
      @click:button="onAddClicked"
    />

    <!-- 새로고침 버튼 -->
    <refresh-button
      :container="mounted ? $refs.container : undefined"
      @click="onRefresh"
    />
  </div>
</template>

<script>
import WindowComponent from "@/assets/plugins/mps-window-mobile/WindowComponent";
import {PATH, CODE} from "@/constants";
import OnMainSearchTriggered from "@/event/OnMainSearchTriggered";
import Work from "@/model/work";
import {NATIVE_EVENT} from "@/assets/plugins/mps-mobile-native-bridge/native-bridge";
import OnMainFilterTriggered from "@/event/OnMainFilterTriggered";
import AddButton from "@/components/comp/AddButton";
import VuexProps from "@/framework/vuex/VuexProps";
import OnFilterResetListener from "@/framework/listeners/OnFilterResetListener";
import RefreshButton from "../../comp/RefreshButton";
import CodeOptions from "@/components/mixins/CodeOptions";
import AsItemCard from "@/components/items/as/AsItemCard";

export default {
  name: "MainAs",
  components: {AsItemCard, RefreshButton, AddButton},
  extends: WindowComponent,
  mixins: [OnMainSearchTriggered, OnMainFilterTriggered, VuexProps, OnFilterResetListener, CodeOptions],
  data() {
    return {
      items: [],
      searchOptions: {},
      identifier: 0,
      mounted: false,
      active: true,
    };
  },
  unmounted() {
    this.$nativeEventBus.$off(NATIVE_EVENT.ON_NOTIFICATION_RECEIVED, this.loadAllUpToCurrent);
    this.active = false
  },
  created() {
    this.initSearchOptions();
    this.$listenerEventBus.$on(this.mpsEvents.ON_MAIN_AS_QUICK_SORT_CHANGED, this.onChangeQuickSort);
  },
  beforeDestroy() {
    this.$listenerEventBus.$off(this.mpsEvents.ON_MAIN_AS_QUICK_SORT_CHANGED, this.onChangeQuickSort);
  },
  mounted() {
    this.mounted = true;
    this.$nativeEventBus.$on(NATIVE_EVENT.ON_NOTIFICATION_RECEIVED, this.loadAllUpToCurrent);
    if (this.historyManager.isForward) { // pushState 한 경우만 초기화
      // 마지막 필터 동작 내용 불러오기
      this.$emit('on-load-save-filter')
      // this.initSearchOptions();
      // this.identifier++;
    } else if (this.historyManager.isBack) {
      // 마지막 필터 동작 내용 불러오기
      this.$emit('on-load-save-filter')
      this.loadAllUpToCurrent();
    }
    this.loadOptions();
    this.active = true
  },
  methods: {
    loadOptions() {
      setTimeout(() => {
        this.loadOptionsForWorkorderStaff();
        this.loadOptionsForDoneReasonCode()
      }, 500);
    },

    async loadAllUpToCurrent() {
      const identifier = this.identifier;
      const response = await this.$request(PATH.WORK_ORDER.LIST)
        .setObject({
          ...this.searchOptions,
          pageNumber: 1,
          pageSize: this.searchOptions.pageNumber * this.searchOptions.pageSize,
        })
        .wait(500)
        .enqueue();
      if (identifier !== this.identifier) return;
      this.items = response.data.map(item => new Work(item));
    },

    /**
     * 빠른정렬이 변경되었습니다.(오류순 or 설치순)
     */
    onChangeQuickSort(model) {
      console.log("oiNChangeQuickSort", model)
      if (!this.active) return;
      if (model) {
        this.searchOptions.sortBy = ["reservationDatetime"]; // 예약
      } else {
        this.searchOptions.sortBy = ["workorderReceiptDate"]; // 접수일시
      }
      this.searchOptions.sortDesc = [true];
      this.identifier++;
    },

    async fetch(page, pageSize) {
      this.searchOptions.pageNumber = page;
      this.searchOptions.pageSize = pageSize;

      const response = await this.$request(PATH.WORK_ORDER.LIST)
        .setObject(this.searchOptions)
        .catch()
        .wait(500)
        .enqueue();
      return response.data.map(e => new Work(e));
    },

    /**
     * 검색키워드와 필터 조건을 초기화 합니다.
     */
    initSearchOptions() {
      this.searchOptions = {};
      this.initFilterState();
    },

    /**
     * 필터 조건을 초기화 합니다.
     */
    initFilterState() {
      this.searchOptions.workorderStateList = [CODE.AS.RECEIVED, CODE.AS.WAITING_STAFF, CODE.AS.IN_PROGRESS];
      this.searchOptions.endDate = this.$now().endOf("day").toISO();
      this.searchOptions.sortBy = ["workorderReceiptDate"];
      this.searchOptions.sortDesc = [true];
      this.searchOptions.isTodayOrAfter = null
      this.searchOptions.isReservation = false
    },

    /**
     * 검색툴바에 엔터 버튼이 눌렸습니다.
     * @param keyword
     */
    onMainSearchTriggered(keyword) {
      this.searchOptions.searchKeyword = keyword;
      this.identifier++;
    },

    /**
     * 필터 조절 callback
     * @param state
     */
    onMainFilterTriggered(state) {
      // this.searchOptions = {...this.searchOptions, ...otherState};

      const {searchKeyword, ...otherState} = state
      this.searchOptions = {...this.searchOptions, ...otherState};
      if (!!searchKeyword && searchKeyword !== '')
        this.searchOptions.searchKeyword = searchKeyword;
      this.identifier++;
    },

    /**
     * 추가 버튼이 눌러졌습니다.
     */
    onAddClicked() {
      this.routerManager.push({name: "AsRegister"});
    },

    /**
     * 필터 조건을 초기화하고 리로드합니다.
     */
    onFilterReset() {
      this.initSearchOptions();
      this.identifier++;
    },

    /**
     * 새로고침 버튼을 클릭하였습니다.
     *
     * 필터조건 변경없이 목록 데이터만 새로 로드합니다.
     */
    onRefresh() {
      this.identifier++;
    },
  }
}
</script>

<style scoped>
</style>
