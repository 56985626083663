<template>
  <div
    class="subtitle-2 mt-5 align-center d-flex justify-center"
    style="margin: 0 auto"
  >
    <img src="@/assets/images/main/svg/icon_error.svg">
    <span class="ml-2">{{ '유효하지 않은 ID 입니다.' | translate }}</span>
  </div>
</template>

<script>
    export default {
        name: "InvalidData",
        props: {
        },
    }
</script>